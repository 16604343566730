import React, { useState, useEffect } from "react"
import { Header, Footer, Aside } from "../components"
import SEO from "../components/seo"
import InputBase from "@mui/material/InputBase"
import SearchIcon from "@mui/icons-material/Search"
import IconButton from "@mui/material/IconButton"
import Paper from "@mui/material/Paper"

import {
  ShortPostWithoutExcerpt,
  TagH1,
  DividerBottom,
  Wrapper,
  Left,
  PostsWrapper,
  Posts,
  AsideWrapper,
  TitleWrapper,
  DividerTop,
  TagSpan,
} from "../elements"
import { GlobalStyle } from "../../src/elements/index"
import algoliasearch from "algoliasearch/lite"
import { graphql, navigate } from "gatsby"
import styled from "styled-components"

const NotFoundWrapper = styled.div`
  text-align: center;
  margin: 100px auto 120px;
`
const NotFoundTitle = styled.h1`
  font-size: 60px;
  line-height: 60px;
  font-family: Montserrat, Noto Sans TC, sans-serif;
  font-weight: 500;
  letter-spacing: -0.1rem;
  margin-bottom: 20px;
`

const NotFoundContent = styled.p`
  line-height: 1.65;
  font-size: 12.5pt;
  font-weight: 400;
  font-family: Montserrat, Noto Sans TC, sans-serif;
  margin-bottom: 35px;
  color: gray;
`

const Container = styled(Paper)`
  padding: 2px 4px;
  display: flex;
  align-items: center;
  width: 70%;
  margin: 0 auto;

  div {
    flex: 1;
    font-family: Amiri;
    color: black;
  }
`
const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_API_KEY
)
const index = searchClient.initIndex(process.env.GATSBY_ALGOLIA_INDEX_NAME)
const SearchPage = ({ location, data }) => {
  let keyword = decodeURI(location.search.slice(1).split("=")[1])
  const map = getPostsMap(data.allMdx.edges)
  const [hits, setHits] = useState([])
  const [mount, setMount] = useState(false)
  const [searchWord, setSearchWord] = useState(keyword)
  const handlePress = event => {
    if (event.key === "Enter") {
      event.target.value = ""
      performSearch()
      setSearchWord("")
    }
  }

  useEffect(() => {
    index
      .search(keyword)
      .then(({ hits }) => {
        setHits(hits)
        setMount(true)
      })
      .catch(err => {
        setHits([])
      })
  }, [keyword])

  const performSearch = () => {
    navigate("/search?keyword=" + searchWord, { replace: true })
  }

  const handleSearchInputChange = event => {
    setSearchWord(event.target.value)
  }
  return (
    <>
      <SEO title="站內搜索" description="站內搜索" />
      <GlobalStyle />
      <Header />
      <div>
        <TitleWrapper>
          <DividerTop />
          <TagSpan>SEARCH RESULTS</TagSpan>
          <TagH1>"{keyword}"</TagH1>
          <DividerBottom />
        </TitleWrapper>
        <Wrapper>
          <Left>
            <PostsWrapper>
              {mount ? (
                hits.length > 0 ? (
                  hits.map(hit => {
                    const post = map.get(hit.objectID)
                    console.log(hit.objectID)
                    const date = new Date(post.date)
                    const img =
                      post.featureImage.childImageSharp.gatsbyImageData
                    return (
                      <Posts key={post.title}>
                        <ShortPostWithoutExcerpt
                          image={img}
                          post={post}
                          date={date}
                        />
                      </Posts>
                    )
                  })
                ) : (
                  <NotFoundWrapper>
                    <NotFoundTitle>Nothing Found</NotFoundTitle>
                    <NotFoundContent>
                      Sorry, but nothing matched your search terms. Please try
                      again with some different keywords.
                    </NotFoundContent>
                    <Container>
                      <InputBase
                        required={true}
                        placeholder="Search Here"
                        inputProps={{ "aria-label": "search ndailynotes" }}
                        style={{ paddingLeft: "10px" }}
                        onChange={handleSearchInputChange}
                        onKeyPress={handlePress}
                      />
                      <IconButton aria-label="search">
                        <SearchIcon />
                      </IconButton>
                    </Container>
                  </NotFoundWrapper>
                )
              ) : (
                <></>
              )}
            </PostsWrapper>
          </Left>
          <AsideWrapper>
            <Aside />
          </AsideWrapper>
        </Wrapper>
      </div>
      <Footer />
    </>
  )
}

const getPostsMap = data => {
  let map = new Map()
  data.forEach(({ node: { id, frontmatter } }) => {
    map.set(id, frontmatter)
  })
  return map
}

export const query = graphql`
  {
    allMdx(filter: { frontmatter: { type: { eq: "post" } } }) {
      edges {
        node {
          id
          frontmatter {
            date
            slug
            tag
            title
            featureImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`

export default SearchPage
